<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import StatCard from "@/components/dashboard/StatCard.vue";
import store from "@/state/store";
import API from "@/plugins/axios-method";
import DataLoader from "@/components/data-loader.vue";

export default {
  page: {
    title: "Starter",
    meta: [{ name: "description", content: 'Application Dashboard' }],
  },
  components: {
    DataLoader,
    StatCard,
    Layout,
    PageHeader,
  },
  data() {
    return {
      url : '/users/statistics/' + store.getters['auth/userId'],
      member_count: 0,
      total_registration_fees: 0,
      total_deposit: 0,
      showDataLoader: true
    };
  },
  mounted() {
    this.getStats()
  },
  methods: {
    getStats(){
      API.get(this.url)
          .then(response => {
            if (response.status === 200){
              const statistics = response.data
              this.member_count = statistics.users
              this.total_registration_fees = statistics.registration
              this.total_deposit = statistics.deposit
            }
          })
          .finally(() => {
            this.showDataLoader = false
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :show-add-button="false" :title="'Dashboard'" :show-search="false"  />
    <section>
      <div class="row">
        <div class="col-12">
          <h5 class="text-decoration-underline mb-3 pb-1">Statistiques Membres</h5>
        </div>
      </div>
      <data-loader v-if="showDataLoader"/>
      <div class="row" v-else-if="!showDataLoader">
        <StatCard
            :amount="total_registration_fees"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Frais d\'Inscriptions'"
        />
        <StatCard
            :amount="total_deposit"
            :is-money="true"
            :is-increase="true"
            :percentage="65"
            :title="'Epargne Totale'"
            :to-link="'/epargnes'"
        />
        <StatCard
            :title="'Adhérents'"
            :to-link="'/utilisateurs'"
            :amount="member_count"
            :is-increase="false"
            :percentage="35"
            :icon="'user-circle'"
            :icon-bg="'primary'"
        />
      </div>
    </section>
  </Layout>
</template>